body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.locationCity {
  color: #fff;
  background-image: linear-gradient(#546477, #26333b);
  border-radius: 6px;
  grid-template-columns: 24% 0 39% 0 19% 0 17% 4%;
  width: 1040px;
  height: 70px;
  padding-left: 15px;
  display: grid;
  box-shadow: 0 3px 6px #00000029;
  font-size: 14px !important;
}

.locationCity .destination {
  width: 100%;
}

.locationCity .destination .MuiInputBase-root, .locationCity .destination .MuiOutlinedInput-root {
  padding-right: 40px !important;
}

.labelText {
  text-align: left;
  letter-spacing: 0;
  color: #fff;
  text-transform: uppercase;
  opacity: 1;
  width: 75px;
  height: 14px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 100;
  position: relative;
  top: 10px;
  font-size: 12px !important;
}

.locationCity .destination input[type="text"] {
  color: #fff;
  background-color: #0000;
  border: none;
  width: 61%;
  padding-top: 17px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 15px;
  box-sizing: content-box !important;
}

.locationCity .destination input[type="text"]::placeholder {
  color: #fff;
  font-size: 10px;
}

.locationCity .destination input[type="text"]:focus {
  outline: none;
}

.locationCity .destination span {
  font-size: 14px;
}

.controlLabel {
  padding-left: 20px;
}

.locationCity .destination p, .locationCity .checkIn p, .locationCity .guest p, .locationCity .hotelClass p {
  color: #fff;
  padding-left: 15px;
  font-size: 12px;
}

.bookNowButton a {
  background-color: #546477;
  height: 70px;
}

.bookNow a {
  color: #fff;
  background-color: #546477;
  border-radius: 6px;
  width: 100%;
  padding: 22px;
  font-size: 14px;
  box-shadow: 0 3px 6px #00000029;
}

.bookNow a:hover {
  background-color: #546477;
}

.useCode .corpCode {
  float: right;
}

.useCode .corpCode .code {
  z-index: 1;
  background-color: #546477;
  border-radius: 6px;
  width: 178px;
  margin-top: -30px;
  position: absolute;
}

.useCode .corpCode p {
  color: #fff;
  padding-left: 10px;
  font-size: 12px;
}

.useCode .corpCode .code Button {
  color: #fff;
  float: right;
  background-color: #2952dd;
  border-radius: 5px;
  margin: 20px 15px 15px 0;
  font-size: 14px;
}

.control {
  padding: "5px";
  width: "150px";
}

button.bookNow {
  letter-spacing: .36px;
  color: #fff;
  background-color: #526781;
  border-radius: 6px;
  width: 100%;
  padding: 16px;
  font-size: 18px;
  font-weight: 100;
}

button.bookNow:hover {
  background-color: #2753eb;
}

.filterReset {
  border-bottom: 1px solid #8080804d;
  width: 100%;
  padding: 5px 0 10px;
  display: inline-block;
}

.filterReset .filter {
  float: left;
  margin-right: 60px;
  font-weight: 500;
  color: #131519 !important;
  opacity: 1 !important;
  padding-left: 0 !important;
  font-size: 14px !important;
}

.filterReset .reset {
  float: left;
  color: #0d38ad;
  font-size: 14px !important;
}

.userProfile {
  float: right;
  color: #fff;
}

.profileName {
  background-color: #1e1e1e;
  min-height: 100%;
  padding: 15px 20px;
  display: flow-root;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.profileName p {
  color: #fff;
  float: left;
}

.closeIcon {
  float: right;
  color: #fff;
  margin-top: 15px;
  font-size: 30pt;
}

.rateRange {
  margin-top: 20px;
  display: flow-root;
}

.rateRange p:first-child {
  float: left;
  color: #131519;
  font-size: 14px;
  font-weight: 500;
}

.rateRange p:nth-child(2) {
  float: right;
  color: #505050;
  font-size: 12px;
}

.ratings h6 {
  color: #131519;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: roboto !important;
}

.meals h6 {
  color: #131519;
  margin-top: 35px;
  font-size: 14px;
  margin-bottom: 5px !important;
  font-family: roboto !important;
}

.memberData {
  width: 100%;
  display: inline-block;
}

.memberName {
  clear: both;
  color: #fff;
  background-image: linear-gradient(#7b6a57, #383731);
  border-radius: 6pt;
  width: 100%;
  padding: 20px 17px 30px;
  display: inline-block;
}

.memberName span {
  line-height: 2em;
}

.memberName .userName {
  font-size: 15pt;
}

.memberName .membership {
  font-size: 13pt;
}

.memberName .medal {
  padding-bottom: 10px;
  font-size: 10pt;
}

.memberName .contact {
  letter-spacing: .15em;
  font-size: 13pt;
}

.memberName .expiry {
  font-size: 10pt;
}

.pointsEarn {
  display: inline-block;
}

.pointsEarn span:first-child {
  color: #fff;
  opacity: .67;
  font-size: 14pt;
}

.pointsEarn span:nth-child(3) {
  letter-spacing: .5em;
  padding-right: 40px;
  font-size: 20pt;
}

.pointsEarn span:nth-child(4) {
  color: #fdc810;
  letter-spacing: .2em;
  font-size: 12pt;
}

.line {
  color: #fdc810;
  height: 1pt;
}

.logout {
  color: #fff;
  opacity: .67;
  font-size: 16pt;
  display: grid;
}

.addRoom {
  cursor: pointer;
  text-transform: uppercase;
  margin-top: -30px;
  z-index: 1 !important;
  font-size: 14px !important;
}

.addRoomHome {
  cursor: pointer;
  text-transform: uppercase;
  margin-top: -33px;
  z-index: 1 !important;
  font-size: 14px !important;
}

.addRoom p {
  text-transform: capitalize;
  margin-top: 0;
  font-weight: 500;
  font-size: 14px !important;
}

.addRoom table tr td .increment {
  border: 1px solid;
  border-radius: 50%;
}

.destination-error-msg {
  float: left;
  color: red;
  background-color: #fdeded;
  border-radius: 3px;
  padding: 5px 10px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.66;
  position: relative;
  bottom: 10px;
  left: -11px;
}

.meals .Mui-checked > svg, .Mobilemeals .Mui-checked > svg {
  background-color: #498dfd;
  border: none;
  border-radius: 3px;
  margin: 3px;
  font-size: 18px;
  color: #fff !important;
}

.meals .MuiFormControlLabel-label, .Mobilemeals .MuiFormControlLabel-label {
  color: #505050;
  padding-top: 1px;
  font-size: 13px;
  font-weight: bold;
}

.meal-checkbox > svg {
  color: #a8a8a8;
}

button.hotelClassApply {
  color: #fff;
  background-color: #546477;
  border-radius: 6px;
  width: 100%;
  padding: 22px;
  font-size: 18px;
  line-height: 1;
  box-shadow: 0 3px 6px #00000029;
}

button.hotelClassApply:hover {
  background-color: #2753eb;
}

.reset span {
  cursor: pointer;
}

svg.starRatingIcon {
  border: 1px solid #a3a3a3;
  width: 40px;
  height: 40px;
  padding: 11px;
}

svg.starRatingIcon:hover {
  cursor: pointer;
}

.bookNowMain {
  border-radius: 6px;
  width: 192px;
  height: 70px;
  padding-left: 20px;
}

.widgetData {
  flex-direction: row;
  display: flex;
}

.applyContent::-webkit-scrollbar {
  width: 5px;
}

.applyContent::-webkit-scrollbar-track {
  background: #d3d3d3;
}

.applyContent::-webkit-scrollbar-thumb {
  background-color: gray;
  border: 3px solid gray;
  border-radius: 6px;
}

.mobileTraveller {
  padding-bottom: 0;
}

.destinationCheckInCheckOut .mobileDestination, .destinationCheckInCheckOut .checkIncheckOut .checkIn, .destinationCheckInCheckOut .checkIncheckOut .checkOut {
  clear: both;
  background-color: #526781;
  border-radius: 6px;
  margin-top: 30px;
  padding: 13px 0 0 14px;
}

.destinationCheckInCheckOut .mobileDestination h6, .destinationCheckInCheckOut .checkIn h6, .destinationCheckInCheckOut .checkIncheckOut .checkOut h6, .destinationCheckInCheckOut .mobileTraveller h6, .destinationCheckInCheckOut .mobileHotelClass h6 {
  color: #fff;
  opacity: 1;
  font-size: 12px;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
}

.destinationCheckInCheckOut .mobileDestination input[type="text"] {
  color: #fff;
  background-color: #0000;
  border: none;
  padding-top: 0;
  padding-left: 0;
  font-size: 12pt;
  padding-bottom: 0 !important;
  position: relative !important;
  top: -4px !important;
}

.destinationCheckInCheckOut .mobileDestination input[type="text"]::placeholder {
  color: #fff;
  font-size: 10px;
}

.destinationCheckInCheckOut .mobileDestination input[type="text"]:focus {
  outline: none;
}

.destinationCheckInCheckOut .checkIncheckOut .checkIn, .destinationCheckInCheckOut .checkIncheckOut .checkOut {
  width: 45%;
  display: inline-block;
}

.destinationCheckInCheckOut .checkIncheckOut .checkIn h6, .destinationCheckInCheckOut .checkIncheckOut .checkOut h6 {
  width: max-content;
}

.destinationCheckInCheckOut .checkIncheckOut .checkOut {
  float: right;
}

.mobileHotelClass, .mobileTraveller {
  background-color: #526781;
  border-radius: 6px;
  margin-top: 30px;
  padding: 13px 0 13px 15px;
}

.bookNow button {
  color: #fff;
  letter-spacing: .36pt;
  border-radius: 6px;
  width: 100%;
  height: 66px;
  margin-top: 30px;
  font-size: 18px;
  box-shadow: 0 3px 6px #00000029;
}

.Mobilemeals h6 {
  color: #131519;
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: Roboto !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: gray;
}

::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.PresetDateRangePicker_button {
  text-align: center;
  color: #3f51b5;
  font: inherit;
  box-sizing: border-box;
  cursor: pointer;
  background: none;
  border: 2px solid #3f51b5;
  height: 100%;
  margin-right: 8px;
  padding: 4px 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
  overflow: visible;
}

.PresetDateRangePicker_panel {
  padding: 0 22px 11px;
}

.PresetDateRangePicker_button:active {
  outline: 0;
}

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #3f51b5;
}

.SingleDatePickerInput {
  background-color: #fff;
  display: inline-block;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
}

.SingleDatePickerInput__rtl {
  direction: rtl;
}

.SingleDatePickerInput__disabled {
  background-color: #f2f2f2;
}

.SingleDatePickerInput__block {
  display: block;
}

.SingleDatePickerInput__showClearDate {
  padding-right: 30px;
}

.SingleDatePickerInput_clearDate {
  color: inherit;
  font: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0 10px 0 5px;
  padding: 10px;
  line-height: normal;
  position: absolute;
  top: 50%;
  right: 0;
  overflow: visible;
  transform: translateY(-50%);
}

.SingleDatePickerInput_clearDate__default:focus, .SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}

.SingleDatePickerInput_clearDate__small {
  padding: 6px;
}

.SingleDatePickerInput_clearDate__hide {
  visibility: hidden;
}

.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  vertical-align: middle;
  width: 15px;
  height: 12px;
}

.SingleDatePickerInput_clearDate_svg__small {
  height: 9px;
}

.SingleDatePickerInput_calendarIcon {
  color: inherit;
  font: inherit;
  cursor: pointer;
  vertical-align: middle;
  background: none;
  border: 0;
  margin: 0 5px 0 10px;
  padding: 10px;
  line-height: normal;
  display: inline-block;
  overflow: visible;
}

.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  vertical-align: middle;
  width: 14px;
  height: 15px;
}

.SingleDatePicker {
  display: inline-block;
  position: relative;
}

.SingleDatePicker__block {
  display: block;
}

.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}

.SingleDatePicker_picker__rtl {
  direction: rtl;
}

.SingleDatePicker_picker__directionLeft {
  left: 0;
}

.SingleDatePicker_picker__directionRight {
  right: 0;
}

.SingleDatePicker_picker__portal {
  background-color: #0000004d;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff;
}

.SingleDatePicker_closeButton {
  color: inherit;
  font: inherit;
  cursor: pointer;
  z-index: 2;
  background: none;
  border: 0;
  padding: 15px;
  line-height: normal;
  position: absolute;
  top: 0;
  right: 0;
  overflow: visible;
}

.SingleDatePicker_closeButton:focus, .SingleDatePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none;
}

.SingleDatePicker_closeButton_svg {
  fill: #cacccd;
  width: 15px;
  height: 15px;
}

.DayPickerKeyboardShortcuts_buttonReset {
  color: inherit;
  font: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-size: 14px;
  line-height: normal;
  overflow: visible;
}

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_show {
  z-index: 2;
  width: 33px;
  height: 26px;
  position: absolute;
}

.DayPickerKeyboardShortcuts_show:before {
  content: "";
  display: block;
  position: absolute;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight:before {
  border-top: 26px solid #0000;
  border-right: 33px solid #3f51b5;
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover:before {
  border-right: 33px solid #afb6e0;
}

.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__topRight:before {
  border-bottom: 26px solid #0000;
  border-right: 33px solid #3f51b5;
  top: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__topRight:hover:before {
  border-right: 33px solid #afb6e0;
}

.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0;
}

.DayPickerKeyboardShortcuts_show__topLeft:before {
  border-bottom: 26px solid #0000;
  border-left: 33px solid #3f51b5;
  top: 0;
  left: 0;
}

.DayPickerKeyboardShortcuts_show__topLeft:hover:before {
  border-left: 33px solid #afb6e0;
}

.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute;
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px;
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px;
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px;
}

.DayPickerKeyboardShortcuts_panel {
  z-index: 2;
  text-align: left;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  margin: 33px;
  padding: 22px;
  position: absolute;
  inset: 0;
  overflow: auto;
}

.DayPickerKeyboardShortcuts_title {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.DayPickerKeyboardShortcuts_list {
  padding: 0;
  font-size: 14px;
  list-style: none;
}

.DayPickerKeyboardShortcuts_close {
  z-index: 2;
  position: absolute;
  top: 22px;
  right: 22px;
}

.DayPickerKeyboardShortcuts_close:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_closeSvg {
  fill: #cacccd;
  width: 15px;
  height: 15px;
}

.DayPickerKeyboardShortcuts_closeSvg:focus, .DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a;
}

.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  vertical-align: middle !important;
  padding: 0 !important;
}

.CalendarDay:active {
  outline: 0;
}

.CalendarDay__defaultCursor {
  cursor: default;
}

.CalendarDay__default {
  color: #484848;
  background: #fff;
  border: 1px solid #e4e7e7;
}

.CalendarDay__default:hover {
  color: inherit;
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
}

.CalendarDay__hovered_offset {
  color: inherit;
  background: #f4f5f5;
  border: 1px double #e4e7e7;
}

.CalendarDay__outside {
  color: #484848;
  background: #fff;
  border: 0;
}

.CalendarDay__outside:hover {
  border: 0;
}

.CalendarDay__blocked_minimum_nights {
  color: #cacccd;
  background: #fff;
  border: 1px solid #eceeee;
}

.CalendarDay__blocked_minimum_nights:active, .CalendarDay__blocked_minimum_nights:hover {
  color: #cacccd;
  background: #fff;
}

.CalendarDay__highlighted_calendar {
  color: #484848;
  background: #ffe8bc;
}

.CalendarDay__highlighted_calendar:active, .CalendarDay__highlighted_calendar:hover {
  color: #484848;
  background: #ffce71;
}

.CalendarDay__selected_span, .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  color: #fff;
  background: #afb6e0;
  border: 1px double #afb6e0;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  color: #fff;
  background: #3f51b5;
  border: 1px double #3f51b5;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover, .CalendarDay__hovered_span:active {
  color: #fff;
  background: #afb6e0;
  border: 1px double #afb6e0;
}

.CalendarDay__blocked_calendar, .CalendarDay__blocked_calendar:active, .CalendarDay__blocked_calendar:hover {
  color: #82888a;
  background: #cacccd;
  border: 1px solid #cacccd;
}

.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
  color: #cacccd;
  background: #fff;
  border: 1px solid #e4e7e7;
}

.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee;
}

.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7;
}

.CalendarMonth {
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  padding: 0 13px !important;
}

.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
}

.CalendarMonth_table td {
  padding: 0 !important;
}

.CalendarMonth_verticalSpacing {
  border-collapse: separate;
}

.CalendarMonth_caption {
  color: #484848;
  text-align: center;
  caption-side: initial;
  text-transform: uppercase;
  padding-top: 22px;
  padding-bottom: 40px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-weight: 500;
}

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px;
}

.CalendarMonthGrid {
  text-align: left;
  z-index: 0;
  background: #fff;
}

.CalendarMonthGrid__animating {
  z-index: 1;
}

.CalendarMonthGrid__horizontal {
  height: 330px;
  position: absolute;
  left: 9px;
}

.CalendarMonthGrid__vertical, .CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
}

.CalendarMonthGrid_month__horizontal {
  vertical-align: top;
  min-height: 100%;
  display: inline-block;
}

.CalendarMonthGrid_month__hideForAnimation {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.CalendarMonthGrid_month__hidden {
  visibility: hidden;
}

.DayPickerNavigation {
  z-index: 2;
  position: relative;
}

.DayPickerNavigation__horizontal {
  height: 0;
}

.DayPickerNavigation__verticalScrollable_prevNav {
  z-index: 1;
}

.DayPickerNavigation__verticalDefault {
  width: 100%;
  height: 52px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.DayPickerNavigation__verticalScrollableDefault {
  position: relative;
}

.DayPickerNavigation__bottom {
  height: auto;
}

.DayPickerNavigation__bottomDefault {
  justify-content: space-between;
  display: flex;
}

.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.DayPickerNavigation_button__default {
  color: #757575;
  background-color: #fff;
  border: 1px solid #e4e7e7;
}

.DayPickerNavigation_button__default:focus, .DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4;
}

.DayPickerNavigation_button__default:active {
  background: #f2f2f2;
}

.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2;
}

.DayPickerNavigation_button__disabled:focus, .DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2;
}

.DayPickerNavigation_button__disabled:active {
  background: none;
}

.DayPickerNavigation_button__horizontalDefault {
  border-radius: 3px;
  padding: 6px 9px;
  line-height: .78;
  position: absolute;
  top: 18px;
}

.DayPickerNavigation_bottomButton__horizontalDefault {
  margin: -10px 22px 30px;
  position: static;
}

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px;
}

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px;
}

.DayPickerNavigation_button__verticalDefault {
  text-align: center;
  background: #fff;
  width: 50%;
  height: 100%;
  padding: 5px;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 5px 2px #0000001a;
}

.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0;
}

.DayPickerNavigation_nextButton__verticalScrollableDefault, .DayPickerNavigation_prevButton__verticalScrollableDefault {
  width: 100%;
}

.DayPickerNavigation_svg__horizontal {
  fill: #82888a;
  width: 19px;
  height: 19px;
  display: block;
}

.DayPickerNavigation_svg__vertical {
  fill: #484848;
  width: 42px;
  height: 42px;
}

.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2;
}

.DayPicker {
  text-align: left;
  background: #fff;
  position: relative;
}

.DayPicker__horizontal {
  background: #fff;
}

.DayPicker__verticalScrollable {
  height: 100%;
}

.DayPicker__hidden {
  visibility: visible;
}

.DayPicker__withBorder {
  border-radius: 3px;
}

.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

.DayPicker_portal__vertical {
  position: initial;
}

.DayPicker_focusRegion {
  outline: 0;
  box-shadow: 0 2px 6px #0000000d, 0 0 0 1px #00000012;
}

.DayPicker_calendarInfo__horizontal, .DayPicker_wrapper__horizontal {
  vertical-align: top;
  display: inline-block;
}

.DayPicker_weekHeaders {
  position: relative;
}

.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}

.DayPicker_weekHeader {
  color: #757575;
  z-index: 2;
  text-align: left;
  position: absolute;
  top: 60px;
}

.DayPicker_weekHeader__vertical {
  left: 50%;
}

.DayPicker_weekHeader__verticalScrollable {
  text-align: center;
  background: #fff;
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
  margin-left: 0;
  display: table-row;
  top: 0;
  left: 0;
}

.DayPicker_weekHeader_ul {
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  list-style: none;
}

.DayPicker_weekHeader_li {
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
}

.DayPicker_transitionContainer {
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  height: 324px !important;
}

.DayPicker_transitionContainer__horizontal {
  transition: height .2s ease-in-out;
}

.DayPicker_transitionContainer__vertical {
  width: 100%;
}

.DayPicker_transitionContainer__verticalScrollable {
  height: 100%;
  padding-top: 20px;
  position: absolute;
  inset: 0;
  overflow-y: scroll;
}

.DateInput__small {
  width: 97px;
}

.DateInput__block {
  width: 100%;
}

.DateInput__disabled {
  color: #dbdbdb;
  background: #f2f2f2;
}

.DateInput_input {
  color: #fff;
  background-color: #0000;
  border: 0;
  border-bottom: 2px solid #0000;
  border-radius: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 17px 11px 9px !important;
}

.DateInput_input__small {
  letter-spacing: .2px;
  padding: 7px 7px 5px;
  font-size: 15px;
  line-height: 18px;
}

.DateInput_input__regular {
  font-weight: auto;
}

.DateInput_input__readOnly {
  -webkit-user-select: none;
  user-select: none;
}

.DateInput_input__focused {
  background: none;
  border: none;
  outline: 0;
}

.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic;
}

.DateInput_screenReaderMessage {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.DateInput_fang {
  z-index: 2;
  width: 20px;
  height: 10px;
  position: absolute;
  left: 22px;
  top: 61px !important;
  bottom: 41px !important;
}

.DateInput_fangShape {
  fill: #fff;
}

.openWidget .DateInput_fangShape {
  opacity: 1;
}

.closeWidget .DateInput_fangShape {
  opacity: 0;
}

.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: #0000;
}

.openWidget .DateInput_fangStroke {
  opacity: 1;
}

.closeWidget .DateInput_fangStroke {
  opacity: 0;
}

.DateRangePickerInput__disabled {
  background: #f2f2f2;
}

.DateRangePickerInput__rtl {
  direction: rtl;
}

.DateRangePickerInput__block {
  display: block;
}

.DateRangePickerInput__showClearDates {
  padding-right: 30px;
}

.widgetDateRange .DateRangePickerInput_arrow {
  padding: 26px 0 0 15px;
  display: inline-block;
}

.DateRangePickerInput_clearDates {
  color: inherit;
  font: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0 10px 0 5px;
  padding: 10px;
  line-height: normal;
  position: absolute;
  top: 50%;
  right: 0;
  overflow: visible;
  transform: translateY(-50%);
}

.DateRangePickerInput_clearDates__small {
  padding: 6px;
}

.DateRangePickerInput_clearDates_default:focus, .DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}

.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}

.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  vertical-align: middle;
  width: 15px;
  height: 12px;
}

.DateRangePickerInput_clearDates_svg__small {
  height: 9px;
}

.DateRangePickerInput_calendarIcon {
  color: #fff;
  font: inherit;
  cursor: pointer;
  vertical-align: middle;
  background: none;
  border: 0;
  margin: 0 5px 0 10px;
  line-height: normal;
  display: inline-block;
  overflow: visible;
}

.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  vertical-align: middle;
  width: 14px;
  height: 15px;
}

.DateRangePicker {
  padding-bottom: 5px;
  display: inline-block;
  position: relative;
}

.DateRangePicker__block {
  display: block;
}

.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
  line-height: 1.5 !important;
}

.openWidget .DateRangePicker_picker {
  display: block;
}

.closeWidget .DateRangePicker_picker {
  display: none;
}

.DateRangePicker_picker__rtl {
  direction: rtl;
}

.DateRangePicker_picker__directionLeft {
  top: 71px !important;
  left: -25px !important;
}

.DateRangePicker_picker__directionRight {
  right: 0;
}

.DateRangePicker_picker__portal {
  background-color: #0000004d;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff;
}

.DateRangePicker_closeButton {
  color: inherit;
  font: inherit;
  cursor: pointer;
  z-index: 2;
  background: none;
  border: 0;
  padding: 15px;
  line-height: normal;
  position: absolute;
  top: 0;
  right: 0;
  overflow: visible;
}

.DateRangePicker_closeButton:focus, .DateRangePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none;
}

.DateRangePicker_closeButton_svg {
  fill: #cacccd;
  width: 15px;
  height: 15px;
}

.DateInput {
  position: relative;
}

.widgetDateRange {
  display: inline-flex;
}

.widgetDateRange .calenderIcon-widget.startDate-icon-widget {
  margin-left: 15px;
  padding-top: 26px;
}

.widgetDateRange .DateRangePicker.DateRangePicker_1 > div {
  height: 100%;
}

.widgetDateRange .DateRangePickerInput.DateRangePickerInput_1 {
  height: 86%;
  margin-left: 0;
  display: inline-flex;
}

.widgetDateRange .DateRangePickerInput_arrow svg, .widgetDateRange .startDate-icon-widget svg {
  vertical-align: -webkit-baseline-middle;
  color: #fff;
  opacity: 1;
  margin-top: 9px;
  font-size: 18px !important;
}

.widgetDateRange .DateInput.DateInput_1:before {
  letter-spacing: 0;
  color: #fff;
  text-transform: uppercase;
  opacity: 1;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  position: relative;
  top: 10px;
  right: 16px;
  font-size: 12px !important;
}

.widgetDateRange .DateInput.DateInput_1:first-of-type:before {
  content: "check-In";
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.widgetDateRange .DateInput.DateInput_1:not(:first-of-type):before {
  content: "check-Out";
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.widgetDateRange .DateInput {
  border: none;
}

#checkInDateWidget, #checkOutDateWidget {
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  background-color: #0000;
  border: none;
  width: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  position: relative;
  right: 17px;
  padding-left: 27px !important;
  padding-right: 0 !important;
}

@media (width >= 1151px) {
  .widgetDateRange .DateInput.DateInput_1:first-of-type {
    border-right: 1px solid #ffffff42;
    height: 70px;
  }
}

@media screen and (width <= 890px) and (width >= 150px) {
  .widgetDateRange .DateRangePickerInput_arrow svg, .widgetDateRange .startDate-icon-widget svg {
    margin-top: 14px !important;
  }
}

@-moz-document url-prefix() {
  .widgetDateRange .DateRangePickerInput_arrow svg, .widgetDateRange .startDate-icon-widget svg {
    vertical-align: -moz-middle-with-baseline;
  }
}

#uo3lzq_scrollChange::-webkit-scrollbar-track {
  background: #d0c8b9;
}

#uo3lzq_scrollChange::-webkit-scrollbar {
  width: 7px;
}

#uo3lzq_scrollChange::-webkit-scrollbar-thumb {
  background: #414141;
}

#uo3lzq_scrollChange::-webkit-scrollbar-thumb:hover {
  background: #414141;
}

.uo3lzq_addRoomGuest p {
  color: #fff;
  margin-top: 10px;
  display: inline-block;
}

.uo3lzq_applyFilterDisabled {
  opacity: .4;
}

.uo3lzq_applyFilter {
  opacity: 1;
}

.uo3lzq_travel {
  color: #fff;
  background-color: #17202b;
  padding: 20px 30px;
}

.uo3lzq_travel .uo3lzq_currencySignUp a {
  color: #fff;
  margin: 15px 15px 0 25px;
  text-decoration: none;
}

.uo3lzq_travel .uo3lzq_currencySignUp {
  justify-content: flex-end;
  padding-top: 5px;
  display: flex;
}

.uo3lzq_roomDetails {
  background-color: #17202b;
  min-height: 100%;
  padding: 15px 30px;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
}

.uo3lzq_roomDetails .uo3lzq_tableData {
  color: #fff;
  background-color: #526781;
  border-radius: 6pt;
  width: 100%;
  margin-top: 30px;
}

.uo3lzq_roomDetails .uo3lzq_tableData h6 {
  color: #fff;
  font-size: 12px;
}

.uo3lzq_roomDetails .uo3lzq_tableData tr td {
  color: #fff;
  border-bottom: none;
  font-size: 14px;
}

.uo3lzq_roomDetails .uo3lzq_tableData tr td span {
  font-size: 12px;
}

.uo3lzq_addAnotherRoom {
  color: #2952dd;
  margin-top: 30px;
  font-size: 18px;
}

.uo3lzq_addAnotherRoom span:nth-child(2) {
  color: #2952dd;
  padding-left: 10px;
  font-size: 18px;
}

.uo3lzq_removeRoom {
  opacity: .39;
  color: #2952dd;
  float: right;
  text-align: center;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 6px;
  width: 110px;
  height: 28px;
  padding-top: 8px;
  font-size: 14px;
  text-decoration: none;
  display: block;
}

.uo3lzq_done {
  width: 100%;
  letter-spacing: .36px !important;
  color: #fff !important;
  background-color: #526781 !important;
  border-radius: 4px !important;
  margin-top: 30px !important;
  padding: 20px 0 !important;
  font-size: 18px !important;
}

.uo3lzq_done:hover {
  background-color: #2952dd;
}

.uo3lzq_selectRateStyle {
  text-overflow: ellipsis;
  width: max-content;
  margin-top: 20px;
  overflow: visible;
}

.uo3lzq_selectRateStyleDLF {
  text-overflow: ellipsis;
  width: max-content;
  margin-top: 3px;
  overflow: visible;
}

.uo3lzq_MuiInputBase-root input[type="text"] {
  background-color: #0000 !important;
  border: none !important;
}

.uo3lzq_selectRateStyle .uo3lzq_selectRateName {
  cursor: pointer;
  width: 80px;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  top: 9px;
}

.uo3lzq_selectRateStyle .uo3lzq_selectRateName span {
  text-transform: initial;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 135px;
  font-size: 14px;
  display: block;
  overflow: hidden;
}

.uo3lzq_promoteRate {
  display: flex;
}

.uo3lzq_promoteRate p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 2;
  position: relative;
}

.uo3lzq_promoteRate .uo3lzq_clear {
  cursor: pointer;
  font-size: 15px;
  position: absolute;
  top: 25px;
  right: 50px;
}

.uo3lzq_apply {
  text-transform: uppercase !important;
  width: 70px !important;
  height: 30px !important;
  margin-top: 20px !important;
  padding: 0 !important;
  font-size: 12px !important;
}

.uo3lzq_applyDLF {
  text-transform: uppercase !important;
  width: 70px !important;
  height: 30px !important;
  margin-top: 14px !important;
  padding: 0 !important;
  font-size: 12px !important;
}

.uo3lzq_promoteRate .uo3lzq_clearCode {
  cursor: pointer;
  font-size: 15px;
  position: absolute;
  top: 25px;
}

.uo3lzq_hoverDetails h3 {
  color: #17202b;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
}

.uo3lzq_hoverDetails span {
  color: #17202b;
  font-size: 14px;
  font-weight: 400;
}

.uo3lzq_alignInfo {
  padding: 5px 15px;
}

.uo3lzq_mobileSelectRate {
  z-index: 9;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 4pt;
  width: 86%;
  margin-top: -60px;
  margin-left: -16px;
  position: absolute;
  box-shadow: 1px 1px 1px #00000029;
}

.uo3lzq_mobileSelectRate .uo3lzq_infoIcon {
  color: #a8a8a8;
  margin: 15px 10px;
}

.uo3lzq_mobileSelectRate .uo3lzq_alignInfo {
  justify-content: space-between;
  display: flex;
}

.uo3lzq_vertLine {
  opacity: .3;
  width: 100%;
  margin: 0;
}

.uo3lzq_mobileSelectRate .uo3lzq_promoteRate .uo3lzq_promoteText {
  margin-left: 30px;
  padding-left: 0 !important;
}

.uo3lzq_mobileSelectRate .uo3lzq_promoteRate .uo3lzq_corporateText {
  justify-content: flex-start;
  display: flex;
}

.uo3lzq_mobileSelectRate .uo3lzq_promoteRate .uo3lzq_apply {
  margin-top: 15px !important;
}

.uo3lzq_arrangeCodeBoxes {
  grid-auto-flow: column;
  gap: 20px;
  display: grid;
}
/*# sourceMappingURL=index.css.map */
