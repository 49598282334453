/* web based start */

/* .App {
  text-align: center;
  background-color: red;
  width:500px;
  height:100px
} */

.locationCity {
  color: #fff;
  width: 1040px;
  border-radius: 6px;
  font-size: 14px !important;
  height: 70px;
  background-image: linear-gradient(#546477, #26333B);
  padding-left: 15px;
  display: grid;
  grid-template-columns: 24% 0px 39% 0px 19% 0px 17% 4%;
  box-shadow: 0px 3px 6px #00000029;
}
.locationCity .destination {
  width: 100%;
}

.locationCity .destination .MuiInputBase-root  {
  padding-right: 40px !important;
}
.locationCity .destination .MuiOutlinedInput-root  {
  padding-right: 40px !important;
}


.labelText {
  position: relative;
    top: 10px;
    width: 75px;
    height: 14px;
    text-align: left;
    font-size: 12px !important;
    font-weight: 100;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.locationCity .destination input[type="text"] {
  width: 61%;
  background-color: transparent;
  border: none;
  color: #fff;
  padding-top: 17px;
  font-size: 15px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  box-sizing: content-box !important;
}
.locationCity .destination input[type="text"]::placeholder {
  font-size: 10px;
  color: #fff;
}
.locationCity .destination input[type="text"]:focus {
  outline: none;
}
.locationCity .destination span {
  font-size: 14px;
}
.controlLabel {
  padding-left:20px;
}
.hotelClass {
  /* position: relative;
  top: -3px;
  left: -13px; */
}
.locationCity .destination p,
.locationCity .checkIn p,
.locationCity .guest p,
.locationCity .hotelClass p {
  font-size: 12px;
  color: #fff;
  padding-left: 15px;
}
.bookNowButton a {
    background-color: #546477;
    height: 70px;
}
.bookNow a {
  background-color: #546477;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  color: #fff;
  font-size: 14px;
  padding: 22px;
  width: 100%;
}
.bookNow a:hover {
  background-color: #546477;
}
.useCode .corpCode {
  float: right;
}
.useCode .corpCode .code {
  background-color: #546477;
  width: 178px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  margin-top: -30px;
}
.useCode .corpCode p {
  font-size: 12px;
  color: #fff;
  padding-left: 10px;
}
.useCode .corpCode .code Button {
  background-color: #2952dd;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  margin: 20px 15px 15px 0px;
  float: right;
}

.control {
  padding: "5px";
  width: "150px";
}

button.bookNow {
  font-weight: 100;
  width: 100%;
  padding: 16px;
  background-color: #526781;
  border-radius: 6px;
  font-size: 18px;
  letter-spacing: 0.36px;
  color: #fff;
}
button.bookNow:hover {
  background-color: #2753eb;
}
.filterReset {
  display: inline-block;
  width: 100%;
  padding: 5px 0px 10px 0px;
  border-bottom: 1px solid #8080804d;
}
.filterReset .filter {
  float: left;
  margin-right: 60px;
  font-weight: 500;
  font-size: 14px !important;
  color: #131519 !important;
  opacity: 1 !important;
  padding-left: 0px !important;
}
.filterReset .reset {
  float: left;
  font-size: 14px !important;
  color: #0d38ad;
}
.userProfile {
  float: right;
  color: #fff;
}
.profileName {
  display: flow-root;
  padding: 15px 20px;
  background-color: #1e1e1e;
  min-height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
}
.profileName p {
  color: #fff;
  float: left;
}
.closeIcon {
  float: right;
  margin-top: 15px;
  font-size: 30pt;
  color: #fff;
}
.rateRange {
  display: flow-root;
  margin-top: 20px;
}
.rateRange p:nth-child(1) {
  float: left;
  font-size: 14px;
  font-weight: 500;
  color: #131519;
}
.rateRange p:nth-child(2) {
  float: right;
  font-size: 12px;
  color: #505050;
}
.ratings h6 {
  color: #131519;
  font-size: 14px;
  margin-bottom: 10px;
  font-family: roboto !important;
}
.meals h6 {
  color: #131519;
  font-size: 14px;
  margin-bottom: 5px !important;
  margin-top: 35px;
  font-family: roboto !important;
}
/* web based end */
.memberData {
  display: inline-block;
  width: 100%;
}
.memberName {
  background-image: linear-gradient(#7b6a57, #383731);
  border-radius: 6pt;
  display: inline-block;
  width: 100%;
  padding: 20px 17px 30px;
  clear: both;
  color: #fff;
}
.memberName span {
  line-height: 2em;
}
.memberName .userName {
  font-size: 15pt;
}
.memberName .membership {
  font-size: 13pt;
}
.memberName .medal {
  font-size: 10pt;
  padding-bottom: 10px;
}
.memberName .contact {
  font-size: 13pt;
  letter-spacing: 0.15em;
}
.memberName .expiry {
  font-size: 10pt;
}
.pointsEarn {
  display: inline-block;
}
.pointsEarn span:first-child {
  font-size: 14pt;
  color: #fff;
  opacity: 67%;
}
.pointsEarn span:nth-child(3) {
  font-size: 20pt;
  letter-spacing: 0.5em;
  padding-right: 40px;
}
.pointsEarn span:nth-child(4) {
  color: #fdc810;
  font-size: 12pt;
  letter-spacing: 0.2em;
}
.line {
  color: #fdc810;
  height: 1pt;
}
.logout {
  font-size: 16pt;
  color: #fff;
  opacity: 67%;
  display: grid;
}
.addRoom {
  z-index: 1 !important;
  margin-top: -30px;
  font-size: 14px !important;
  cursor: pointer;
  text-transform: uppercase;
  /*

    margin-top: 0px;
    font-weight: 500;
    text-transform: capitalize; */
}
.addRoomHome {
  z-index: 1 !important;
  margin-top: -33px;
  font-size: 14px !important;
  cursor: pointer;
  text-transform: uppercase;
}
.addRoom p {
  font-size: 14px !important;
  margin-top: 0px;
  font-weight:500;
  text-transform: capitalize;
}
.addRoom table tr td .increment {
  border: 1px solid;
  border-radius: 50%;
}
.destination-error-msg {
    line-height: 1.66;
    font-weight: 400;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    position: relative;
    bottom: 10px;
    left: -11px;
    border-radius: 3px;
    background-color: rgb(253, 237, 237);
    float: left;
    font-size: 14px;
    color: red;
    padding: 5px 10px;
}
.meals .Mui-checked > svg, .Mobilemeals .Mui-checked > svg {
  background-color: #498DFD;
  color: white !important;
  border: none;
  font-size: 18px;
  border-radius: 3px;
  margin: 3px;
}
.meals .MuiFormControlLabel-label, .Mobilemeals .MuiFormControlLabel-label {
  padding-top: 1px;
  font-size: 13px;
  font-weight: bold;
  color: #505050
}
.meal-checkbox > svg {
  color: #A8A8A8;
}
button.hotelClassApply {
  background-color: #546477;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  color: #fff;
  font-size: 18px;
  padding: 22px;
  width: 100%;
  line-height: 1;
}
button.hotelClassApply:hover {
  background-color: #2753eb;
}
.reset span {
  cursor: pointer;
}
svg.starRatingIcon {
  border: 1px solid #A3A3A3;
  padding: 11px;
  width: 40px;
  height: 40px;
}
svg.starRatingIcon:hover {
  cursor: pointer;
}
.bookNowMain{
  width: 192px;
  height: 70px;
  border-radius: 6px;
  padding-left: 20px;
}
.widgetData{
  display: flex;
  flex-direction: row;
}
/* --------Apply Filter scroll bar css--------- */
.applyContent::-webkit-scrollbar {
  width: 5px;
}
.applyContent::-webkit-scrollbar-track {
  background: lightgray
}
.applyContent::-webkit-scrollbar-thumb  {
  background-color: grey ;
  border-radius: 6px;
  border: 3px solid grey;
}
/* mobile css */

.mobileTraveller{
  padding-bottom: 0px;
}
.destinationCheckInCheckOut .mobileDestination, .destinationCheckInCheckOut .checkIncheckOut .checkIn, .destinationCheckInCheckOut .checkIncheckOut .checkOut{
  background-color: #526781;
  border-radius: 6px;
  clear: both;
  padding: 13px 0px 0px 14px;
  margin-top: 30px;
}
.destinationCheckInCheckOut .mobileDestination h6, .destinationCheckInCheckOut .checkIn h6, .destinationCheckInCheckOut .checkIncheckOut .checkOut h6, .destinationCheckInCheckOut .mobileTraveller h6, .destinationCheckInCheckOut .mobileHotelClass h6{
  color: #fff;
  opacity: 1;
  font-size: 12px;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
}
.destinationCheckInCheckOut .mobileDestination input[type='text']{
  border: none;
  font-size: 12pt;
  background-color: transparent;
  color: #fff;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px !important;
  top: -4px !important;
  position: relative  !important;
}
.destinationCheckInCheckOut .mobileDestination input[type='text']::placeholder{
  font-size: 10px;
  color: #fff;
}
.destinationCheckInCheckOut .mobileDestination input[type='text']:focus{
  outline: none;
}
.destinationCheckInCheckOut .checkIncheckOut .checkIn, .destinationCheckInCheckOut .checkIncheckOut .checkOut{
  width: 45%;
  display: inline-block;
}
.destinationCheckInCheckOut .checkIncheckOut .checkIn h6, .destinationCheckInCheckOut .checkIncheckOut .checkOut h6{
  width: max-content;
}
.destinationCheckInCheckOut .checkIncheckOut .checkOut{
  float: right;
}
.mobileHotelClass, .mobileTraveller{
  background-color: #526781;
  border-radius: 6px;
  margin-top: 30px;
  padding: 13px 0px 13px 15px;
}
.bookNow button{
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  height: 66px;
  color: #fff;
  font-size: 0.36px;
  font-size: 18px;
  width: 100%;
  margin-top: 30px;
  letter-spacing: 0.36pt;
}
.Mobilemeals h6{
  color: #131519;
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: Roboto !important;
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: grey;
}

::-webkit-scrollbar-thumb:hover {
  background: grey;
}

/* mobile css end */
/* @media (max-width: 1110px) and (min-width: 500px) {
  .locationCity{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 55vw;
  }
  .widgetDateRange .calenderIcon-widget.startDate-icon-widget{
    margin-left: 0px !important;
  }
  .controlLabel{
    padding-left: 0px;
    margin-bottom: 10px;
  }
  .guestData{
    height: 70px;
    border-bottom: 1px solid #ffffff42;
    width: 50vw;
  }
  .bookNowResponsive{
    width: 100% !important;
  }
  .lineHeight{
    height: 0px !important;
  }
  .bookNowMain{
    padding-left: 0px;
    margin-top: 30px;
  }
  .widgetData{
    flex-direction: column;
  }
  .responsiveDialog{
    position: relative;
    left: 0px;
  }
  .locationCity .destination{
    border-bottom: 1px solid #ffffff42;
    width: 50vw;
    margin-bottom: 10px;
  }
  .marginDestination{
    padding-bottom: 0px;
  }
} */